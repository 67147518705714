import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { Headline2Sans } from "../../system42";
import * as styles from "./styles.module.css";

export function FeatureGrid(props) {
  const { className, title, features } = props;

  const featurePairs = [];
  for (let i = 0; i < features.length; i = i + 2) {
    const feature1 = features[i];
    const feature2 = features[i + 1];
    featurePairs.push([feature1, feature2]);
  }

  return (
    <div className={cn(className, styles.featureGrid)}>
      {title && <Headline2Sans className={styles.title}>{title}</Headline2Sans>}
      <div className={styles.gridsContainer}>
        {featurePairs.map((pair, index) => (
          <div className={styles.grid} key={index}>
            <div className={styles.gridCol}>
              {pair[0].captionTop && (
                <div
                  className={cn(
                    styles.captionTop,
                    pair[0].captionAlternativeColor &&
                      styles.captionTop_alternativeColor
                  )}
                >
                  {pair[0].captionTop}
                </div>
              )}
              {pair[0].imageSrc && (
                <img src={pair[0].imageSrc} alt={pair[0].imageAlt} />
              )}
              {pair[0].captionBottom && (
                <div
                  className={cn(
                    styles.captionBottom,
                    pair[0].captionAlternativeColor &&
                      styles.captionBottom_alternativeColor
                  )}
                >
                  {pair[0].captionBottom}
                </div>
              )}
              {pair[0].customNode && pair[0].customNode}
            </div>
            {pair[1] && (
              <div className={styles.gridCol}>
                {pair[1].captionTop && (
                  <div
                    className={cn(
                      styles.captionTop,
                      pair[1].captionAlternativeColor &&
                        styles.captionTop_alternativeColor
                    )}
                  >
                    {pair[1].captionTop}
                  </div>
                )}
                {pair[1].imageSrc && (
                  <img src={pair[1].imageSrc} alt={pair[1].imageAlt} />
                )}
                {pair[1].captionBottom && (
                  <div
                    className={cn(
                      styles.captionBottom,
                      pair[1].captionAlternativeColor &&
                        styles.captionBottom_alternativeColor
                    )}
                  >
                    {pair[1].captionBottom}
                  </div>
                )}
                {pair[1].customNode && pair[1].customNode}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
