// extracted by mini-css-extract-plugin
export var button = "tester-pool-module--button--89509";
export var buttonSubtext = "tester-pool-module--buttonSubtext--3eabd";
export var featureGridAdvertedPoolSize = "tester-pool-module--featureGridAdvertedPoolSize--ffcaa";
export var featureIcon = "tester-pool-module--featureIcon--05e62";
export var header = "tester-pool-module--header--7c117";
export var headerPortrait1 = "tester-pool-module--headerPortrait1--31ae1";
export var headerPortrait2 = "tester-pool-module--headerPortrait2--75c04";
export var headerPortrait3 = "tester-pool-module--headerPortrait3--6af60";
export var headerPortrait4 = "tester-pool-module--headerPortrait4--b36a7";
export var intro = "tester-pool-module--intro--6bc7a";
export var overline = "tester-pool-module--overline--e8765";
export var title = "tester-pool-module--title--fc68f";